@import './fonts/index.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --animate-duration: 450ms;
  --fa-secondary-opacity: 0.15;
}

html {
  @apply antialiased font-body bg-gray-100 text-gray-700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-heading;
}

.modal::before {
  width: 0;
  height: 100%;
  content: '';
  display: inline-block;
  vertical-align: middle;
}

.title span::after {
  content: '';
  @apply bg-primary;
  position: absolute;
  left: 0px;
  bottom: -10px;
  width: 100%;
  height: 4px;
}

.tippy-content {
  padding: 0px !important;
}

.fa-spin {
  animation: fa-spin 0.8s infinite linear;
}
